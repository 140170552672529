// Plugins
import Choices from "./../../../../assets/vendor/choices.js/public/assets/scripts/choices";
import GLightbox from '../../../../assets/vendor/glightbox/glightbox.min.js';
import IMask from "../../../../assets/vendor/imask";

// Local
import Animate from './partials/animate';
import Form from "./partials/form";
import Alert from "./partials/alert";
import Collapsible from "./partials/collapsible";
import Modal from "./partials/modal";
import Tabs from "./partials/tabs";
import Autocomplete from "./partials/autocomplete";
import Dropdown from "./partials/dropdown";
import PasswordControl from "./partials/password-control";
import Header from "./partials/header";
import ImageButton from "./partials/image-button";
import Loader from "./partials/loader";
import ChunkedUpload from "./partials/chunked-upload";

window.initUiKit = ($wrapper) => {
    $wrapper.querySelectorAll('[data-header]').forEach($item => (new Header($item)));
    $wrapper.querySelectorAll('[data-animate]').forEach($item => (new Animate($item)));
    $wrapper.querySelectorAll('[data-form]').forEach($el => (new Form($el)));
    $wrapper.querySelectorAll('[data-alert]').forEach($el => (new Alert($el)));
    $wrapper.querySelectorAll('[data-collapsible]').forEach($el => (new Collapsible($el)));
    $wrapper.querySelectorAll('[data-modal]').forEach($el => (new Modal($el)));
    $wrapper.querySelectorAll('[data-tabs]').forEach($el => (new Tabs($el)));
    $wrapper.querySelectorAll('[data-dropdown]').forEach($el => (new Dropdown($el)));
    $wrapper.querySelectorAll('[data-password]').forEach($el => (new PasswordControl($el)));
    $wrapper.querySelectorAll('[data-autocomplete]').forEach($el => (new Autocomplete($el)));
    $wrapper.querySelectorAll('[data-image-button]').forEach($el => (new ImageButton($el)));
    $wrapper.querySelectorAll('[data-chunked-upload]').forEach($el => (new ChunkedUpload($el)));
    $wrapper.querySelectorAll('[data-loader]').forEach($el => (new Loader($el)));
    $wrapper.querySelectorAll('[data-mask]').forEach(($el) => new IMask($el, {mask: $el.dataset.mask}));
    $wrapper.querySelectorAll('[data-select]').forEach($el => {
        (new Choices($el, {
            placeholder: $el.dataset.selectPlaceholder !== undefined,
            placeholderValue: $el.dataset.selectPlaceholder,
            searchEnabled: $el.querySelectorAll('option').length > 8,
            noResultsText: translations.noResultsText,
            noChoicesText: translations.noChoicesText,
            itemSelectText: '',
            uniqueItemText: translations.uniqueItemText,
            customAddItemText: translations.customAddItemText,
            removeItemButton: false,
        }));
    });

    GLightbox({
        selector: '[data-glightbox]',
        touchNavigation: true,
        loop: true,
        autoplayVideos: true
    });
}

(function() {
    window.initUiKit(document);
})();