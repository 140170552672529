export const handleError = (error) => {
    switch (error) {
        case 400:
            sendError('Error 400');
            break;
        case 404:
            sendError('Error 404');
            break;
        case 500:
            sendError('Dem server guys messed up again: 500');
            break;
        default:
            sendError(error);
            break;
    }
}

const sendError = (message) => {
    console.log(message);
}