// import '../../../../../assets/vendor/scroll-timeline-polyfill/dist/scroll-timeline.js';

export default class ImageButton {
    constructor($el) {
        this.$el = $el;
        this.reverseDirection = this.$el.dataset.imageButton === 'reverse';
        this.$img = this.$el.querySelector('[data-image]');
        this.$starWrapper = this.$el.querySelector('[data-star-wrapper]');

        this.initAnimations();
    }

    initAnimations() {

        if (typeof ViewTimeline === 'undefined') {
            return;
        }

        if (this.$img) {
            this.$img.animate(
                    {
                        transition: ['transform ease .2s', 'transform ease .2s'],
                        transform: ['translateY(0)', 'translateY(30%) scale(1.02)']
                    },
                    {
                        fill: 'both',
                        timeline: new ViewTimeline({
                            subject: this.$img,
                            axis: "block",
                            inset: [CSS.percent(0), CSS.percent(100)],
                        }),
                    }
            );
        }

        this.$starWrapper.animate(
            {
                transition: ['transform ease .2s', 'transform ease .2s'],
                transform: ['translateY(0)', this.reverseDirection ? 'translateY(-90px) rotate(-25deg)' : 'translateY(-90px) rotate(25deg)']
            },
            {
                fill: 'both',
                timeline: new ViewTimeline({
                    subject: this.$starWrapper,
                    axis: "block",
                    inset: [CSS.percent(0), CSS.percent(100)],
                }),
            }
        );
    }
}