export default class StickyContainer {
    constructor($container) {
        this.$container = $container;
        this.$button = this.$container.querySelector('[data-sticky-button]');

        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.checkButtonVisibility = this.checkButtonVisibility.bind(this);

        this.$button.addEventListener('click', this.scrollToBottom);
        window.addEventListener('scroll', this.checkButtonVisibility);

        this.checkButtonVisibility();
    }

    /**
     * Scroll to bottom
     * @param e
     */
    scrollToBottom(e) {
        e.preventDefault();
        this.$container.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    /**
     * Check button visibility
     */
    checkButtonVisibility() {
        const rect = this.$container.getBoundingClientRect();
        const treshold = 20;
        const containerBottomPosition = (rect.top + window.scrollY + this.$container.offsetHeight) - treshold;
        const windowBottomPosition = window.scrollY + window.innerHeight;

        if(window.innerHeight >= containerBottomPosition) {
            this.$button.classList.add('sticky-button--hidden', windowBottomPosition >= containerBottomPosition);
        } else {
            this.$button.classList.toggle('sticky-button--hidden', windowBottomPosition >= containerBottomPosition);
        }

    }
}