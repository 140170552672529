export default class FormSteps {
    constructor($form, form) {
        this.$form = $form;
        this.form = form;
        this.$steps = this.$form.querySelectorAll('[data-form-step]');
        this.$stepTriggers = this.$form.querySelectorAll('[data-fom-step-trigger]');
        this.$prevStepButton = this.$form.querySelector('[data-form-prev-step]');
        this.$nextStepButton = this.$form.querySelector('[data-form-next-step]');
        this.$submitButton = this.$form.querySelector('[data-form-submit]');
        this.currentStepIndex = 0;

        if (this.$form.querySelectorAll('.form__error').length && this.getFirstStepIndexWithError() !== -1) {
            this.currentStepIndex = this.getFirstStepIndexWithError();
        }

        this.updateSteps = this.updateSteps.bind(this);
        this.goToStep = this.goToStep.bind(this);
        this.goToPreviousStep = this.goToPreviousStep.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.stepHasErrors = this.stepHasErrors.bind(this);

        this.$stepTriggers.forEach( ($stepTrigger, index) => {
            $stepTrigger.addEventListener('click' , event => this.goToStep(event, index));
        });

        this.$prevStepButton.addEventListener('click', this.goToPreviousStep);
        this.$nextStepButton.addEventListener('click', this.goToNextStep);

        this.updateSteps();
    }

    /**
     * Update steps
     *
     * handle the rendering of the steps, stepTriggers and next & prev buttons
     */
    updateSteps() {
        this.$steps.forEach(($step, index) => {
            $step.classList.toggle('d-none', index !== this.currentStepIndex)
        });

        this.$stepTriggers.forEach(($stepTrigger, index) => {
            if (index < this.currentStepIndex) {
                $stepTrigger.classList.add('stepper__item--completed');
                $stepTrigger.classList.remove('stepper__item--active');
            } else if (index === this.currentStepIndex) {
                $stepTrigger.classList.add('stepper__item--active');
                $stepTrigger.classList.remove('stepper__item--completed');
            } else {
                $stepTrigger.classList.remove('stepper__item--active', 'stepper__item--completed');
            }

            // add error class if the step has errors
            $stepTrigger.classList.toggle('stepper__item--error', this.stepHasErrors(index));
        });

        this.$prevStepButton.classList.toggle('d-none', this.currentStepIndex === 0);
        this.$nextStepButton.classList.toggle('d-none', (this.currentStepIndex + 1) === this.$steps.length);
        this.$submitButton.classList.toggle('d-none', (this.currentStepIndex + 1) !== this.$steps.length);
    }

    /**
     * Go to the given step
     * @param e
     * @param index
     */
    goToStep(e, index) {
        e.preventDefault();
        this.validateCurrentStep();

        this.currentStepIndex = index;
        this.updateSteps();
    }

    /**
     * Go to the previous step
     */
    goToPreviousStep() {
        this.validateCurrentStep();

        if (this.currentStepIndex > 0) {
            this.currentStepIndex--;
            this.updateSteps();
        }
    }

    /**
     * Go to the next step
     */
    goToNextStep() {
        this.validateCurrentStep();

        if ((this.currentStepIndex + 1) < this.$steps.length) {
            this.currentStepIndex++;
            this.updateSteps();
        }
    }

    validateCurrentStep() {
        const currentStep = this.getCurrentStep();
        console.log(currentStep);
        this.form.inputs.forEach(input => {
            if (currentStep.contains(input.$control)) {
                input.validate();
            }
        });
    }

    /**
     * Check if a step has errors by its index
     * @param stepIndex
     * @return {boolean}
     */
    stepHasErrors(stepIndex) {
        return this.$steps[stepIndex].querySelectorAll('.form__group--error').length > 0;
    }

    /**
     * Get the first step index with an error
     * @return {number}
     */
    getFirstStepIndexWithError() {
        for (let index = 0; index < this.$steps.length; index++) {
            if (this.stepHasErrors(index)) {
                return index; // Return the index of the first step with an error
            }
        }
        return -1;
    }

    /**
     * Get current step
     * @return {*}
     */
    getCurrentStep() {
        return this.$steps[this.currentStepIndex];
    }
}