import Swiper from "../../../../../assets/vendor/swiper/swiper-bundle.esm.browser";

export default class HeroSlider {
    constructor($slider) {
        this.$slider = $slider;

        this.slider = new Swiper(this.$slider, {
            slidesPerView: 1,
            watchSlidesProgress: true,
            effect: 'fade',
            autoplay: true,
            delay: 8000, // 8s
            autoHeight: true,
            navigation: {
                nextEl: '[data-swiper-next]',
                prevEl: '[data-swiper-prev]',
            },
            on: {
                init: function () {
                    setTimeout(() => {
                        this.updateAutoHeight(0);
                    }, 500);
                },
            }
        });
    }
}