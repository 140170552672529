export default class Tabs {
    constructor($wrapper) {
        this.$wrapper = $wrapper;
        this.$triggers = this.$wrapper.querySelectorAll('[data-tab-trigger]');
        this.$tabs = this.$wrapper.querySelectorAll('[data-tab]');
        // We can have tabs using the tab ui element as triggers but we can also have segment buttons be the trigger for tabs
        // So here we check wich active trigger class we need to use for the current instance
        this.activeTabClass =  this.$triggers[0]?.classList.contains('segment-button')
                ? 'segment-button--active'
                : 'tab--active';

        this.tabClicked = this.tabClicked.bind(this);
        this.setTabActive = this.setTabActive.bind(this);
        this.checkActiveTab = this.checkActiveTab.bind(this);

        this.$triggers.forEach($trigger => $trigger.addEventListener('click', this.tabClicked));

        this.checkActiveTab();
    }

    checkActiveTab() {
        const tabId = window.location.hash.replace('#', '');

        if (! tabId || ! this.$wrapper.querySelector('[data-tab="' + tabId + '"]')) {
            return;
        }

        this.setTabActive(tabId);
    }

    /**
     * Tab clicked
     * @param e
     */
    tabClicked(e) {
        e.preventDefault();
        const tabId = e.target.closest('[data-tab-trigger]').dataset.tabTrigger;

        this.setTabActive(tabId);
    }

    /**
     * set tab active
     * @param tabId
     */
    setTabActive(tabId) {
        const $tab =  this.$wrapper.querySelector('[data-tab="' + tabId + '"]');
        const $trigger =  this.$wrapper.querySelector('[data-tab-trigger="' + tabId + '"]');

        this.$triggers.forEach($trigger => $trigger.classList.remove(this.activeTabClass));
        this.$tabs.forEach($tab => $tab.classList.remove('tab-content--active'));

        $tab.classList.add('tab-content--active');
        $trigger.classList.add(this.activeTabClass);

        window.location.hash = '#' + tabId;
    }
}