export default class Collapsible {

    /**
     * Constructor
     * @param $alert
     */
    constructor($collapsible) {
        this.$collapsible = $collapsible;
        this.$trigger = this.$collapsible.querySelector('[data-collapsible-trigger]');
        this.$content = this.$collapsible.querySelector('[data-collapsible-content]');

        this.toggle = this.toggle.bind(this);

        this.$trigger.addEventListener('click', this.toggle);
    }

    /**
     * Toggle collapsible
     * @param e
     */
    toggle(e) {
        e.preventDefault();
        this.$trigger.classList.toggle('collapsible__trigger--active');
        this.$content.classList.toggle('collapsible__content--active');
    }
}