import Videoplayer from "./videoplayer";

export default class Modal {
    /**
     * Constructor
     * @param $modal
     */
    constructor($modal) {
        this.$modal = $modal;
        this.$content = $modal.querySelector('[data-modal-content]');
        this.id = $modal.dataset.modal;
        this.$triggers = document.querySelectorAll(`[data-open-modal="${this.id}"]`);
        this.$closeTriggers = this.$modal.querySelectorAll(`[data-close-modal="${this.id}"]`);
        this.$videoPlayer = this.$modal.querySelector('[data-videoplayer]');
        this.videoPlayer = this.$videoPlayer
                ? (new Videoplayer(this.$videoPlayer))
                : null;

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.$triggers.forEach( $trigger => $trigger.addEventListener('click', this.openModal));
        this.$closeTriggers.forEach( $trigger => $trigger.addEventListener('click', this.closeModal));

        // click outside
        document.addEventListener('click', (e) => {
            const isClickInside = e.target.closest('[data-modal-content]');
            const isCloseTrigger = e.target.closest('[data-close-modal]');
            let isTrigger = false;

            if (!isClickInside && !isTrigger && !isCloseTrigger) {
                this.closeModal();
            }

            if (e.target.dataset?.openModal == this.id || e.target.closest('[data-open-modal]')?.dataset?.openModal == this.id) {
                this.openModal(e);
                isTrigger = true;
            }
        });

        if(! localStorage.getItem(this.id + '-seen') && this.$modal.hasAttribute('data-one-time-modal')) {
            window.addEventListener('DOMContentLoaded', this.openModal);
        }

        // Move element to root
        document.querySelector('body').after(this.$modal);
    }

    /**
     * Open modal
     * @param e
     */
    openModal(e) {
        e.preventDefault();
        e.stopPropagation();
        this.$modal.classList.add('modal--visible');
        setTimeout(() => document.querySelector('body').classList.add('no-scroll'), 10);

        if (this.videoPlayer) {
            this.videoPlayer.play(e);
        }
    }

    /**
     * Close modal
     * @param e
     */
    closeModal(e) {
        if(e) {
            e.preventDefault();
        }
        this.$modal.classList.remove('modal--visible');
        localStorage.setItem(this.id + '-seen', true, Infinity);
        document.querySelector('body').classList.remove('no-scroll');

        if (this.videoPlayer) {
            this.videoPlayer.pause(e);
        }
    }
}