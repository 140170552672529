import Swiper from "../../../../../assets/vendor/swiper/swiper-bundle.esm.browser";

export default class QuoteSlider {
    constructor($wrapper) {
        this.$slider = $wrapper.querySelector('[data-slider]');

        this.slider = new Swiper(this.$slider, {
            slidesPerView: 1,
            spaceBetween: 24,
            navigation: {
                nextEl: '[data-swiper-next]',
                prevEl: '[data-swiper-prev]',
            }
        });
    }
}