export default {
    template: document.querySelector('[data-brand-filter-modal-template]') ?
            document.querySelector('[data-brand-filter-modal-template]').innerHTML:
            null,
    props: {
        show: {
            type: Boolean,
        },
        brands: {
            type: Array,
        },
        activeBrands: {
            type: Array,
        }
    },
    computed: {
        /**
         * Get inactive brands
         * @return {*[]}
         */
        inActiveBrands() {
            if (! this.brands) {
                return [];
            }


            return this.brands.filter( brand => !this.activeBrands.includes(brand) && ! brand.archived);
        },
        /**
         * Get inactive brands
         * @return {*[]}
         */
        inActiveBrandsArchived() {
            if (! this.brands) {
                return [];
            }


            return this.brands.filter( brand => !this.activeBrands.includes(brand) && brand.archived);
        }
    },
    methods: {
        /**
         * Check if brand is active
         * @param brand
         * @return {boolean}
         */
        isActive(brand) {
            return this.activeBrands.includes(brand);
        },
        /**
         * Add filter brand
         * @param brand
         */
        addFilterBrand(brand) {
            this.$emit('addFilterBrand', brand);
            this.close();
        },
        /**
         * Remove filter brand
         * @param brand
         */
        removeFilterBrand(brand) {
            this.$emit('removeFilterBrand', brand);
        },
        /**
         * Close
         */
        close() {
            this.$emit('close');
        },
        /**
         * Check click outside
         * @param e
         */
        checkClickOutside(e) {
            if ( ! this.$refs.content.contains(e.target)) {
                this.close();
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('click', this.checkClickOutside);
    }
}