export default class InlineCollapsable {
    constructor($trigger) {
        this.$trigger = $trigger;
        this.$targets = this.nextUntil(this.$trigger, '.collapsable-trigger');

        this.toggle = this.toggle.bind(this);

        this.$trigger.addEventListener('click', this.toggle);
    }

    toggle(e) {
        e.preventDefault();
        this.$trigger.classList.toggle('collapsable-trigger--active');
        this.$targets.forEach($elem => $elem.classList.toggle('collapsable--expanded'));
    }

    nextUntil (elem, selector, filter) {
        // Setup siblings array
        var siblings = [];

        // Get the next sibling element
        elem = elem.nextElementSibling;

        // As long as a sibling exists
        while (elem) {

            // If we've reached our match, bail
            if (elem.matches(selector)) break;

            // If filtering by a selector, check if the sibling matches
            if (filter && !elem.matches(filter)) {
                elem = elem.nextElementSibling;
                continue;
            }

            // Otherwise, push it to the siblings array
            siblings.push(elem);

            // Get the next sibling element
            elem = elem.nextElementSibling;

        }

        return siblings;
    };
}