import {createApp} from "vue3";
import {get} from "../../../../../assets/js/shared/data-manager";
import Loader from "./loader";
import AjaxListBrandModal from "./ajax-list-brand-modal";
import AjaxListShowModal from "./ajax-list-show-modal";
import AjaxPageJumper from "./ajax-page-jumper";

/**
 * Ajax list
 */
export default class AjaxList {
    /**
     * Construct
     * @param $element
     */
    constructor($element) {
        this.$element = $element;
        this.$list = this.$element.querySelector('[data-list]');

        this.createVueApp();
    }

    /**
     *
     */
    createVueApp() {
        const blockId = this.$element.dataset.blockId;

        const app = createApp({
            data() {
                return {
                    blockId: blockId,
                    translations: translations,
                    result: {},
                    fetchParams: {},
                    pager: null,
                    publications: [],
                    settings: {},
                    abortController: null,
                    timeout: null,
                    initialized: false,
                    loader: null,
                    loading: false,
                    isBrandModalVisible: false,
                    isShowModalVisible: false,
                }
            },
            watch: {
                loading() {
                    this.loader.loading(this.loading);
                }
            },
            methods: {
                /**
                 * Active filters
                 * @param objects
                 * @param name
                 * @return {*|*[]}
                 */
                activeFilterValues(objects, name) {
                    if (! objects) {
                        return [];
                    }

                    return objects.filter( object => this.fetchParams[name].includes(object.slug));
                },
                /**
                 * Load More
                 */
                loadMore() {
                    this.setPage(this.fetchParams[translations.page] + 1, true, false);
                },
                /**
                 * Set page
                 * @param page
                 * @param keepItems
                 */
                changePage(page, keepItems = false, scrollToTop = true) {
                    this.fetchParams[translations.page] = page;
                    this.updateActiveFiltersInUrl();

                    this.fetchPublications(keepItems, scrollToTop);
                },
                /**
                 * Get the publication data
                 *
                 * @param keepItems
                 * @returns {Promise<void>}
                 */
                async fetchPublications(keepItems = false, scrollToTop = false) {

                    if(scrollToTop) {
                        this.scrollToTop();
                    }

                    const params = {
                        ...this.fetchParams
                    };
                    const url = window.location.origin + window.location.pathname + `/block/${blockId}/ajax`;
                    if (this.abortController) {
                        this.abortController.abort();
                    }

                    this.loading = true;
                    this.abortController = new AbortController();

                    this.result = await get(url.toString(), params, this.abortController.signal);

                    if( ! this.result) {
                        return;
                    }

                    // Set the values we need
                    this.publications = keepItems
                        ? [...this.publications, ...this.result.publications]
                        : this.result.publications;
                    this.settings = this.result.settings;
                    this.pager = this.result.pager;

                    this.loading = false;
                    // bind other js logic like animate
                    this.$nextTick(() => {
                        window.initUiKit(this.$refs.list);
                        window.initDom(this.$refs.list);
                    });
                },
                /**
                 * Add filter
                 *
                 * @param name
                 * @param value
                 */
                addFilter(name, value) {
                    this.fetchParams[translations.page] = 1;
                    this.fetchParams[name] = value;

                    this.updateActiveFiltersInUrl();
                    this.fetchPublications(false, true);
                },
                /**
                 * Add filter value
                 *
                 * @param name
                 * @param value
                 */
                addFilterValue(name, value) {
                    this.fetchParams[translations.page] = 1;
                    this.fetchParams[name].push(value);

                    this.updateActiveFiltersInUrl();
                    this.fetchPublications(false, true);
                },
                /**
                 * Remove filter
                 *
                 * @param name
                 */
                removeFilter(name) {
                    this.fetchParams[translations.page] = 1;
                    delete this.fetchParams[name];

                    this.updateActiveFiltersInUrl();
                    this.fetchPublications(false, true);
                },
                /**
                 * remove filter value
                 *
                 * @param name
                 * @param value
                 */
                removeFilterValue(name, value) {
                    const index = this.fetchParams[name].indexOf(value);

                    if (index > -1) {
                        this.fetchParams[translations.page] = 1;
                        this.fetchParams[name].splice(index, 1);
                        this.updateActiveFiltersInUrl();
                        this.fetchPublications(false, true);
                    }
                },
                /**
                 * Set brand modal visibility
                 * @param isVisible
                 */
                setBrandModalVisibility(isVisible) {
                    this.isBrandModalVisible = isVisible;
                },
                /**
                 * Set show modal visibility
                 * @param isVisible
                 */
                setShowModalVisibility(isVisible) {
                    this.isShowModalVisible = isVisible;
                },
                /**
                 * Open in new tab
                 * @param url
                 */
                openInNewTab(url) {
                    window.open(url, '_blank').focus();
                },
                scrollToTop() {
                    this.$el.scrollIntoView();
                },
                /**
                 * Update the url width the active filters
                 */
                updateActiveFiltersInUrl() {
                    const url = (new URL(window.location.origin + window.location.pathname));

                    Object.keys(this.fetchParams).forEach(filterName => {
                        const filterValue = this.fetchParams[filterName];
                        if (! filterValue) {
                            return;
                        }

                        if (Array.isArray(filterValue)) {
                            if (! filterValue.length) {
                                return;
                            }
                            url.searchParams.append(filterName, filterValue.join(','));
                        } else {
                            url.searchParams.append(filterName, filterValue);
                        }
                    });

                    window.history.pushState({},null, url.toString());
                },
                /**
                 * Initialize current filters
                 * Get the current filters from the url and apply them
                 */
                initializeCurrentFilters() {
                    const urlParams = new URLSearchParams(document.location.search);
                    this.fetchParams[translations.page] = urlParams.get(translations.page)
                            ? urlParams.get(translations.page)
                            : 1;

                    this.fetchParams[translations.brands] = urlParams.get(translations.brands)
                            ? urlParams.get(translations.brands).split(",")
                            : [];

                    this.fetchParams[translations.shows] = urlParams.get(translations.shows)
                            ? urlParams.get(translations.shows).split(",")
                            : [];

                    this.fetchPublications();
                }
            },
            async mounted() {
                this.initialized = true;
                this.loader = (new Loader(this.$refs.loader));

                this.initializeCurrentFilters();

                window.addEventListener("popstate", this.initializeCurrentFilters);
            },
            beforeDestroy() {
                window.removeEventListener("popstate", this.initializeCurrentFilters);
            }
        });

        app.config.compilerOptions.delimiters = ['v{', '}'];

        app.component('brand-modal',  AjaxListBrandModal);
        app.component('show-modal',  AjaxListShowModal);
        app.component('ajax-page-jumper',  AjaxPageJumper);

        // mount the app
        app.mount(this.$list);
    }
}