import Loader from "./loader";
import FormControl from "./form-control";
import Messenger from "./messenger";

export default class Newsletter {
    constructor($form) {
        this.$form = $form;
        this.$email = this.$form.querySelector('input[type="email"]');
        this.$emailControl = (new FormControl(this.$email));
        this.$submit = this.$form.querySelector('[data-submit]');
        this.loader =  this.$form.querySelector('[data-newsletter-loader]')
                ? (new Loader(this.$form.querySelector('[data-newsletter-loader]')))
                : (new Loader(document.querySelector('[data-loader]')));

        this.submitNewsletter = this.submitNewsletter.bind(this);
        this.sendNewsletterSubscription = this.sendNewsletterSubscription.bind(this);
        this.handleError = this.handleError.bind(this);

        this.$form.addEventListener('submit', this.submitNewsletter);
    }

    /**
     * submit newsletter
     *
     * @param e
     * @return {Promise<void>}
     */
    async submitNewsletter(e) {
        e.preventDefault();
        e.stopPropagation();

        this.loader.loading(true);

        if (! this.$emailControl.validate(e, false)) {
            this.loader.loading(false);
            (new Messenger()).show(translations.emailInvalidNewsletter, 'error');
            return;
        }

        const result = await this.sendNewsletterSubscription();

        if(result) {
            this.loader.loading(false);
            location.href = result.redirect;
        }
    }

    /**
     * Send newsletter subscription
     *
     * @return {Promise<any>}
     */
    async sendNewsletterSubscription() {
        const formData = new FormData(this.$form);

        try {
            const request = await fetch(`${placeholders.router.baseUri}/newsletter/optin`, {
                method: 'POST',
                body: new FormData(this.$form)
            });

            // if something when't wrong
            if (!request.ok) {
                // we throw the error of the request
                throw request.status;
            }
            const response = await request.json();

            return response;
        } catch (error) {
            this.handleError(error);
        }
    }

    /**
     * Handle error
     *
     * @param error
     */
    handleError(error) {
        console.log(error);
        switch (error) {
            case 404:
                console.log(error);
                window.location = pageNotFoundUrl;
                break;
            case 500:
                console.log(error);
                window.location = errorPageUrl;
                break;
            default:
                console.log(error);
                break;
        }
    }
}