export default {
    template: document.querySelector('[data-ajax-page-jumper-template]') ?
            document.querySelector('[data-ajax-page-jumper-template]').innerHTML:
            null,
    data() {
        return {
            firstPage: null,
            lastPage: null,
            maxAmountOfSteps: null,
        }
    },
    props: {
        pager: {
            type: Object,
        },
        maxAmountOfStepsDesktop: {
            type: Number,
            default: 5
        },
        maxAmountOfStepsMobile: {
            type: Number,
            default: 3
        }
    },
    computed: {
        /**
         * Active page
         * @return {*}
         */
        activePage() {
            return this.pager.page.number;
        },
        /**
         * Construct the current visible pages
         * @return {number[]|unknown[]}
         */
        currentPages() {
            // If only a single page is present, we can stop already
            if(this.pager.numberOfPages == 1) {
                return [1];
            }

            // Calculate the maximum delta that is allowed between the this.firstPage
            // page and the this.lastPage page
            const diff = this.maxAmountOfSteps - 1;

            // Calculate the this.firstPage page visible in the interface, making sure it
            // is at least 1
            this.firstPage = this.activePage - (Math.floor(this.maxAmountOfSteps / 2));

            if (this.firstPage < 1) {
                this.firstPage = 1;
            }

            // Calculate the this.lastPage visible page, making sure it doesn't exceed
            // the maximum number of available pages
            this.lastPage = this.firstPage + diff;
            if (this.lastPage > this.pager.numberOfPages) {
                this.lastPage = this.pager.numberOfPages;
            }

            // Do we have enough pages this way?
            if (this.lastPage - this.firstPage < this.maxAmountOfSteps) {
                // We do not, which means we're approaching the this.lastPage few pages.
                // Rewind the starting point, as long as that is allowed
                while ((this.lastPage - this.firstPage) < diff && this.firstPage > 1) {
                    this.firstPage --;
                }
            }

            // We're done! Output an array of all page numbers that are to be
            // rendered in the interface:
            return [...Array(this.lastPage - this.firstPage + 1).keys()].map(i => i + this.firstPage);
        },
        showFirstDots() {
            return (this.firstPage - 1) > 1;
        },
        showLastDots() {
            return (this.lastPage + 1) < this.pager.numberOfPages;
        },
        showFirstPage() {
            return this.firstPage > 1;
        },
        showLastPage() {
            return this.lastPage < this.pager.numberOfPages;
        }
    },
    methods: {
        /**
         * Go to page
         * @param page
         */
        goToPage(page) {
            this.$emit('change-page', page);
        },
        /**
         * Go forwad 10 pages if it is in the current page range
         * @param page
         */
        goToMaxNext() {
            let futurePage = this.activePage + 10;

            futurePage = futurePage >= this.pager.numberOfPages  ? this.pager.numberOfPages : futurePage;
            this.goToPage(futurePage);
        },
        /**
         * Go back 10 pages if it is in the current page range
         * @param page
         */
        goToMaxPrev() {
            let futurePage = this.activePage - 10;

            futurePage =  futurePage <= 0 ? 0 : futurePage;
            this.goToPage(futurePage);
        },

        /**
         * Set Max amount of step
         */
        setMaxAmountOfSteps() {
            this.maxAmountOfSteps = (window.innerWidth < 768) ?
                    this.maxAmountOfStepsMobile:
                    this.maxAmountOfStepsDesktop;
        }
    },
    created() {
        window.addEventListener('resize', this.setMaxAmountOfSteps);
        this.setMaxAmountOfSteps()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setMaxAmountOfSteps);
    }
};