import Swiper from "../../../../../assets/vendor/swiper/swiper-bundle.esm.browser";

export default class CtcSlider {
    constructor($wrapper) {
        this.$slider = $wrapper.querySelector('[data-slider]');

        this.slider = new Swiper(this.$slider, {
            slidesPerView: 1,
            spaceBetween: 24,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '[data-swiper-next]',
                prevEl: '[data-swiper-prev]',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        });
    }
}