export default class BackButton {
    constructor($el) {
        this.$el = $el;
        this.goBack = this.goBack.bind(this);

        // We check if the referrer adres contains our elemnts href
        // if so we well use the history back logic to redirect back so we can keep the previous query params
        if (this.matchingReferer()) {
            this.$el.addEventListener('click', this.goBack);
        }
    }

    /**
     * We check if the referrer adres contains our elemnts href
     *
     */
    matchingReferer() {
        return document.referrer.includes(this.$el.getAttribute('href'));
    }

    goBack(e) {
        e.preventDefault();
        window.history.back();
    }
}