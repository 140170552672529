import Swiper from "../../../../../assets/vendor/swiper/swiper-bundle.esm.browser";

export default class Gallery {
    constructor($wrapper) {
        this.$wrapper = $wrapper;
        this.$slider = $wrapper.querySelector('[data-slider]');

        new Swiper(this.$slider, {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            slidesPerView: 1,
            speed: 350,
            centeredSlides: true,
            centeredSlidesBounds: true,
            watchSlidesProgress: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }
}