export default {
    template: document.querySelector('[data-show-filter-modal-template]') ?
            document.querySelector('[data-show-filter-modal-template]').innerHTML:
            null,
    props: {
        show: {
            type: Boolean,
        },
        shows: {
            type: Array,
        },
        activeShows: {
            type: Array,
        }
    },
    computed: {
        /**
         * Get inactive shows
         * @return {*[]}
         */
        inActiveShows() {
            if (! this.shows) {
                return [];
            }

            return this.shows.filter( show => !this.activeShows.includes(show) && ! show.archived);
        },
        /**
         * Get inactive shows
         * @return {*[]}
         */
        inActiveShowsArchived() {
            if (! this.shows) {
                return [];
            }

            return this.shows.filter( show => !this.activeShows.includes(show) && show.archived);
        }
    },
    methods: {
        /**
         * Check if show is active
         * @param show
         * @return {boolean}
         */
        isActive(show) {
            return this.activeShows.includes(show);
        },
        /**
         * Add filter show
         * @param show
         */
        addFilterShow(show) {
            this.$emit('addFilterShow', show);
            this.close();
        },
        /**
         * Remove filter show
         * @param show
         */
        removeFilterShow(show) {
            this.$emit('removeFilterShow', show);
        },
        /**
         * Close
         */
        close() {
            this.$emit('close');
        },
        /**
         * Check click outside
         * @param e
         */
        checkClickOutside(e) {
            if ( ! this.$refs.content.contains(e.target)) {
                this.close();
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('click', this.checkClickOutside);
    }
}