export default class Alert {

    /**
     * Constructor
     * @param $alert
     */
    constructor($alert) {
        this.$alert = $alert;
        this.$closeAlert = this.$alert.querySelector('[data-close]');

        this.closeAlert = this.closeAlert.bind(this);

        if (this.$closeAlert) {
            this.$closeAlert.addEventListener('click', this.closeAlert);
        }
    }

    /**
     * Close alert
     * @param e
     */
    closeAlert(e) {
        e.preventDefault();

        this.$alert.remove();
    }
}