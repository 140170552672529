export default class Animate {constructor($el) {
    this.$el = $el;
    const treshold = $el.dataset.treshold ? $el.dataset.treshold : 60;

    const options = {
        threshold: this.$el.offsetHeight > treshold ? (treshold / this.$el.offsetHeight): .10, // if 10% is visible
    }
    this.checkAnimate = this.checkAnimate.bind(this);
    this.observer = new IntersectionObserver(this.checkAnimate, options);

    this.observer.observe(this.$el);
}

    checkAnimate(entries, observer) {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                this.$el.classList.add(`animate`);
            }
            // else if(!entry.isIntersecting && entry.boundingClientRect.y > 0) {
            //     this.$el.classList.remove(`animate`);
            // }
        });
    }
}