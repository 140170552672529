import gsap from '../../../../../assets/vendor/gsap/gsap.min.js';
import ScrollTrigger from '../../../../../assets/vendor/gsap/ScrollTrigger.min.js';
import Swiper from "../../../../../assets/vendor/swiper/swiper-bundle.esm.browser";

export default class MPower {
    constructor($el) {
        this.$el = $el;
        this.$slider = this.$el.querySelector('[data-slider]');
        gsap.registerPlugin(ScrollTrigger);

        this.itemsHeight = this.$el.querySelector('[data-stacked-items-left]').scrollHeight;
        this.$leftItems = this.$el.querySelectorAll('[data-stacked-item-left]');
        this.$rightItems = this.$el.querySelectorAll('[data-stacked-item-right]');
        this.itemHeight = this.itemsHeight / this.$leftItems.length;
        this.itemCount = this.$leftItems.length;
        this.letterOffset = 0;

        this.leftItemAnimation = this.leftItemAnimation.bind(this);
        this.rightItemAnimation = this.rightItemAnimation.bind(this);
        this.goToSlide = this.goToSlide.bind(this);

        this.$leftItems.forEach(this.leftItemAnimation);
        this.$rightItems.forEach(this.rightItemAnimation);

        this.initSlider();
    }

    leftItemAnimation ($leftItem, key) {
        const $letter = $leftItem.querySelector('[data-letter]');
        const $letter2 = $leftItem.querySelector('[data-letter-2]');
        const $title = $leftItem.querySelector('[data-title]');

        gsap.to($leftItem, {
            opacity: 1,
            translateY: (key * -1) * this.itemHeight,
            scrollTrigger: {
                trigger: this.$el,
                start: () => (key * this.itemHeight) + 377,
                end: () => ((key + 1) * this.itemHeight) + 377,
                markers: false,
                invalidateOnRefresh: true,
                toggleActions: "play none reverse none",
                snap: true
            }
        });

        gsap.to($letter, {
            opacity: .3,
            fontSize: '48px',
            paddingBottom: 0,
            translateY: '-60%',
            translateX: this.letterOffset,
            scrollTrigger: {
                trigger: this.$el,
                start: () => (key * this.itemHeight) + 377,
                end: () => ((key + 1) * this.itemHeight) + 377,
                markers: false,
                invalidateOnRefresh: true,
                toggleActions: "play none reverse none",
                snap: true
            }
        });
        this.letterOffset += ($letter.offsetWidth * .6) + 2;


        if (this.itemCount !== (key + 1)) {
            gsap.to($letter2, {
                opacity: 0,
                scrollTrigger: {
                    trigger: this.$el,
                    start: () => (key + 1) * this.itemHeight + 377,
                    end: () => (key + 2) * this.itemHeight + 377,
                    markers: false,
                    invalidateOnRefresh: true,
                    toggleActions: "play none reverse none",
                    snap: true
                }
            });

            gsap.to($title, {
                opacity: 0,
                scrollTrigger: {
                    trigger: this.$el,
                    start: () => (key + 1) * this.itemHeight + 377,
                    end: () => (key + 2) * this.itemHeight + 377,
                    markers: false,
                    invalidateOnRefresh: true,
                    toggleActions: "play none reverse none",
                    snap: true,
                }
            });
        }
    }

    rightItemAnimation($rightItem, key) {
        const $rightItemInner = $rightItem.querySelector('[data-stacked-item-inner]');

        gsap.to($rightItem, {
            opacity: 1,
            translateY: (key * -1) * this.itemHeight,
            scrollTrigger: {
                trigger: this.$el,
                start: () => (key * this.itemHeight) + 377,
                end: () => ((key + 1) * this.itemHeight) + 377,
                invalidateOnRefresh: true,
                toggleActions: "play none reverse none",
                snap: true,
            }
        });

        if (this.itemCount !== (key + 1)) {
            gsap.to($rightItemInner, {
                opacity: 0,
                translateY: ((key ? key: 1) * -this.itemHeight),
                scrollTrigger: {
                    trigger: this.$el,
                    start: () => (key + 1) * this.itemHeight + 377,
                    end: () => (key + 2) * this.itemHeight + 377,
                    invalidateOnRefresh: true,
                    toggleActions: "play none reverse none",
                    snap: true,
                }
            });
        }
    }

    /**
     * Init slider
     */
    initSlider() {
        this.$slideNavigationTriggers = this.$slider.querySelectorAll('[data-go-to-slide]');
        this.slider = new Swiper(this.$slider, {
            slidesPerView: 1,
            effect: "fade",
            autoplay: true,
            fadeEffect: { crossFade: true },
            navigation: {
                nextEl: '[data-swiper-next]',
                prevEl: '[data-swiper-prev]',
            }
        });

        this.$slideNavigationTriggers.forEach($trigger => $trigger.addEventListener('click', this.goToSlide));
    }

    /**
     * Go to slide
     * @param e
     */
    goToSlide(e) {
        const slideId = e.target.dataset.goToSlide;
        this.slider.slideTo(slideId);
    }
}