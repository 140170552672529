import moment from "../../../../../assets/vendor/moment/min/moment-with-locales.min";

export default class FormControl {
    constructor($control) {
        this.$control = $control;
        this.$label = this.$control.parentNode.querySelector('[data-form-label]');
        this.$clear = this.$control.parentNode.querySelector('[data-form-clear]');
        this.$formGroup = this.$control.closest('[data-form-group]');
        this.isRequired = this.$formGroup && this.$formGroup.dataset.isRequired == 'true';

        this.checkState = this.checkState.bind(this);
        this.isFocused = this.isFocused.bind(this);
        this.clearControl = this.clearControl.bind(this);
        this.validateBasicText = this.validateBasicText.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateDate = this.validateDate.bind(this);
        this.validateSelect = this.validateSelect.bind(this);
        this.validateCheckbox = this.validateCheckbox.bind(this);
        this.validateCheckboxes = this.validateCheckboxes.bind(this);
        this.setValidationStatus = this.setValidationStatus.bind(this);
        this.validateAddressSelect = this.validateAddressSelect.bind(this);
        this.validateAddress = this.validateAddress.bind(this);
        this.validate = this.validate.bind(this);
        this.getType = this.getType.bind(this);
        this.getValue = this.getValue.bind(this);

        this.$control.addEventListener('input', this.checkState);
        this.$control.addEventListener('focus', this.checkState);
        this.$control.addEventListener('blur', this.checkState);
        this.$control.addEventListener('change', this.checkState);
        this.$control.addEventListener('blur', this.validate);

        if (this.getType() === 'select' || this.getType() === 'address-select') {
            this.$control.addEventListener('change', this.validate);
        }

        if (this.$clear) {
            this.$clear.addEventListener('click', this.clearControl);
        }

        window.addEventListener('load', this.checkState);
    }

    /**
     * Check state
     */
    checkState(e) {
        // if we don't have a label we skip this
        if (! this.$label) {
            return;
        }

        // check if we need to add active label
        if (this.$control.value || this.isFocused()) {
            this.$label.classList.add('form__label--active');
            this.$control.classList.add('form__control--active');
        } else {
            this.$label.classList.remove('form__label--active');
            this.$control.classList.remove('form__control--active');
        }
    }

    /**
     * Is focused
     * @return {boolean}
     */
    isFocused() {
        return document.activeElement  === this.$control
    }

    /**
     * Clear control
     * @param e
     */
    clearControl(e) {
        e.preventDefault();
        this.$control.value = null;
        this.checkState();
    }


    /**
     * Validate control
     * @return {boolean}
     */
    validate($event, showMessage = false) {
        let valid = true;
        const type = this.getType();
        let message = null;

        switch (type) {
            case "address-text":
                if( ! this.validateAddress()) {
                    valid = false;
                    message = translations.controlRequired;
                }
                break;
            case "address-select":
                if( ! this.validateAddressSelect() ) {
                    valid = false;
                    message = translations.controlRequired;
                }
                break;
            case "email":
                if( ! this.validateBasicText() ) {
                    valid = false;
                    message = translations.controlRequired;
                    break;
                }
                if( ! this.validateEmail() ) {
                    valid = false;
                    message = translations.emailInvalid;
                }
                break;
            case "date":
                if( ! this.validateBasicText() ) {
                    valid = false;
                    message = translations.controlRequired;
                }
                if( ! this.validateDate() ) {
                    valid = false;
                    message = translations.dateInvalid;
                }
                break;
            case "select":
                if( ! this.validateSelect() ) {
                    valid = false;
                    message = translations.controlRequired;
                }
                break;
            case "checkbox":
                if( ! this.validateCheckbox() ) {
                    valid = false;
                    message = translations.controlRequired;
                }
                break;
            case "checkboxes":
                if( ! this.validateCheckboxes() ) {
                    valid = false;
                    message = translations.controlRequired;
                }
                break;
            default:
                if( ! this.validateBasicText() ) {
                    valid = false;
                    message = translations.controlRequired;
                }
                break;
        }

        if(type.includes('address')) {
            this.setAddressValidationStatus(valid, showMessage ? message : null);
        } else {
            this.setValidationStatus(valid, showMessage ? message : null);
        }

        return valid;
    }

    /**
     * Get control type
     * @return {string}
     */
    getType() {
        const type = this.$control.getAttribute('type');
        const element = this.$control.nodeName.toLowerCase();

        if (this.$control.closest('[data-address]') && element === 'select') {
            return 'address-select';
        }

        if (this.$control.closest('[data-address]') && element === 'input' && type === "text") {
            return 'address-text';
        }

        if (element === 'select') {
            return 'select';
        }

        if (element === 'textarea') {
            return 'textarea';
        }

        if (element === 'input' && type === 'email') {
            return 'email';
        }

        if (element === 'input' && this.$control?.hasAttribute('data-date')) {
            return 'date';
        }

        if (element === 'input' && type === 'phone') {
            return 'phone';
        }

        if (element === 'input' && type === "text") {
            return 'text';
        }

        if (element === 'input' && type === "file") {
            return 'file';
        }

        if (element === 'input' && type === "radio") {
            return 'radio';
        }

        if (element === 'input' && type === "checkbox"  && this.$control?.getAttribute('name')?.includes('[]')) {
            return 'checkboxes';
        }

        if (element === 'input' && type === "checkbox") {
            return 'checkbox';
        }

        return type;
    }

    /**
     * Get value
     * @return {*}
     */
    getValue() {
        return this.$control.value;
    }

    /**
     * Set validation status
     *
     * @param valid
     * @param message
     */
    setValidationStatus(valid, message = null) {
        this.$formGroup.querySelector('[data-inline-error]')?.remove();

        if(valid) {
            this.$formGroup?.classList.add('form__group--success');
            this.$formGroup?.classList.remove('form__group--error');
            this.$formGroup.querySelector('.form__error')?.remove();
        } else {
            this.$formGroup?.classList.remove('form__group--success');
            this.$formGroup?.classList.add('form__group--error');
            this.setErrorMessage(message);
        }
    }

    /**
     * Set error message
     * @param message
     */
    setErrorMessage(message) {
        if ( ! message) {
            return;
        }

        const errorDiv = document.createElement('div');
        errorDiv.classList.add('form__error');
        errorDiv.setAttribute('data-inline-error', true);
        errorDiv.innerHTML = message;

        this.$formGroup.append(errorDiv);
    }

    /**
     * Set validation status
     *
     * @param valid
     * @param message
     */
    setAddressValidationStatus(valid, message = null) {
        const $addressRow = this.$control.closest('.form__address-field');
        if(valid) {
            $addressRow.classList.add('form__address-field--success');
            $addressRow.classList.remove('form__address-field--error');
            $addressRow.querySelector('.form__error')?.remove();
            this.$formGroup.querySelector('.form__error')?.remove();
        } else {
            $addressRow.classList.remove('form__address-field--success');
            $addressRow.classList.add('form__address-field--error');
        }
    }

    /**
     * Validate select
     * @returns {boolean}
     */
    validateSelect() {
        return !(this.getValue() === "" || this.getValue() === "--" && this.isRequired);
    };

    /**
     * Validate Checkbox
     * @returns {boolean}
     */
    validateCheckbox() {
        return (this.$control.checked && this.isRequired) || ! this.isRequired;
    };

    /**
     * Validate Checkboxes
     * @returns {boolean}
     */
    validateCheckboxes() {
        const fieldName = this.$control.getAttribute("name");
        return (document.querySelectorAll('input[name="' + fieldName + '"]:checked').length && this.isRequired)  || ! this.isRequired;
    };

    /**
     * Validate basic text
     * @returns {boolean}
     */
    validateBasicText() {
        return !((this.getValue() === "" || this.getValue() === undefined) && this.isRequired);
    };

    /**
     * Validate email
     * @returns {boolean}
     */
    validateEmail() {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(this.getValue().trim());
    };

    /**
     * Validate date
     * @return {boolean}
     */
    validateDate() {
        if(this.getValue().length < 10 ) {
            return false;
        }

        const date = moment(this.getValue().trim(),'DD/MM/YYYY',true);
        return date.isValid();
    };

    /**
     * Validate address
     * @returns {boolean}
     */
    validateAddress() {
        return !((this.getValue() === "" || this.getValue() === undefined) && this.isRequired);
    };

    /**
     * Validate address select
     * @returns {boolean}
     */
    validateAddressSelect() {
        return !(this.getValue() === "" || this.getValue() === "--") ;
    };
}