export default class Videoplayer {
    constructor($wrapper) {
        this.$wrapper = $wrapper;
        this.$video = this.$wrapper.querySelector('video');
        this.$play = this.$wrapper.querySelector('[data-play]');
        this.$pause = this.$wrapper.querySelector('[data-pause]');
        this.$mute = this.$wrapper.querySelector('[data-mute]');
        this.$unmute = this.$wrapper.querySelector('[data-unmute]');

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.mute = this.mute.bind(this);
        this.unmute = this.unmute.bind(this);

        this.$play.addEventListener('click', this.play);
        this.$pause.addEventListener('click', this.pause);
        this.$mute.addEventListener('click', this.mute);
        this.$unmute.addEventListener('click', this.unmute);

        if( window.innerWidth < 992) {
            this.pause();
        }
    }

    play(e) {
        if(e) {
            e.preventDefault();
        }
        this.$video.play();
        this.$play.classList.add('d-none');
        this.$pause.classList.remove('d-none');
    }

    pause(e) {
        if(e) {
            e.preventDefault();
        }
        this.$video.pause();
        this.$pause.classList.add('d-none');
        this.$play.classList.remove('d-none');
    }

    mute(e) {
        e.preventDefault();
        this.$video.muted = true;
        this.$mute.classList.add('d-none');
        this.$unmute.classList.remove('d-none');
    }

    unmute(e) {
        e.preventDefault();
        this.$video.muted = false;
        this.$unmute.classList.add('d-none');
        this.$mute.classList.remove('d-none');
    }
}