export default class LinkTo {
    constructor($el) {
        this.$el = $el;

        this.handleClick = this.handleClick.bind(this);

        this.$el.addEventListener("click", this.handleClick);
    }


    /**
     * handleClick
     * @param event
     */
    handleClick(event) {
        // Check if the click happened inside another <a> tag
        if (event.target.closest("a")) {
            return; // Let the default behavior happen
        }

        const url = this.$el.dataset.href;
        const targetBlank = this.$el.getAttribute("target") === "_blank";

        if (! url) {
            return;
        }

        if (targetBlank) {
            window.open(url, "_blank");
        } else {
            window.location.href = url;
        }
    }
}