import {createApp} from "vue3";
import VueUploadComponent from "../../../../../assets/vendor/vue3-upload-component/dist/vue-upload-component";

export default class ChunkedUpload {
    constructor($element) {
        this.$element = $element;
        this.init();
    }

    init() {
        const app = createApp({
            data: function () {
                return {
                    files: [],
                    error: null,
                    isMounted: false
                }
            },
            components: {
                FileUpload: VueUploadComponent
            },
            computed: {
                /**
                 * In progress status
                 * @return {*}
                 */
                inProgress() {
                    return this.isMounted && this.$refs['upload'] && this.$refs['upload'].active;
                },
                /**
                 * Current progress percentage
                 * @return {number}
                 */
                progressPercentage() {
                    const totalPercentage = this.files.length * 100;
                    let currentPercentage = 0;

                    this.files.forEach(file => currentPercentage += file.progress);
                    return (currentPercentage / totalPercentage) * 100;
                },
                /**
                 * Check if files are successfully uploaded
                 * @return {*|boolean}
                 */
                isSuccess() {
                    return this.isMounted && this.$refs['upload'] && !this.$refs['upload'].active && this.files.length;
                }
            },
            methods: {
                /**
                 * Has changed
                 * @param  Object|undefined   newFile   Read only
                 * @param  Object|undefined   oldFile   Read only
                 * @return undefined
                 */
                inputFile: function (newFile, oldFile) {
                    if (newFile && oldFile && !newFile.active && oldFile.active && newFile.response.status == "success") {
                        // Upload was succesful
                        newFile.filePath = newFile.response.filePath;
                    }

                    // if we have a new file we automaticly trigger the upload
                    if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                        if (!this.$refs.upload.active) {
                            this.$refs.upload.active = true
                        }
                    }
                },
                /**
                 * Pretreatment
                 * @param  Object|undefined   newFile   Read and write
                 * @param  Object|undefined   oldFile   Read only
                 * @param  Function           prevent   Prevent changing
                 * @return undefined
                 */
                inputFilter: function (newFile, oldFile, prevent) {
                    // if we don't have a new file we do nothing
                    if(! newFile) {
                        return;
                    }

                    // filter out dragged files with the wrong extension
                    const extensions = this.$refs.upload.extensions;
                    const mimeTypes = this.$refs.upload?.mimeTypes;

                    const fileExtension = newFile.name.split('.').pop().toLowerCase();
                    if (extensions.length > 0 && extensions.indexOf(fileExtension) === -1) {
                        this.error = translations.uploadExtensionInvalid.replace('@type', fileExtension);
                        console.log(extensions);
                        this.error = translations.uploadExtensionInvalid.replace('@acceptedTypes', extensions);
                        return prevent();
                    }

                    // filter out dragged files with the wrong mime type
                    if (mimeTypes && mimeTypes.length > 0 && (mimeTypes.indexOf(newFile.type) === -1 || newFile.type === "")) {
                        this.error = translations.uploadExtensionInvalid.replace('@type', fileExtension);
                        this.error = translations.uploadExtensionInvalid.replace('@acceptedTypes', mimeTypes);
                        return prevent();
                    }

                    // Create a blob field
                    newFile.blob = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.blob = URL.createObjectURL(newFile.file)
                    }
                },
                /**
                 * Remove an existing file
                 * @param file
                 */
                remove(file) {
                    this.$refs.upload.remove(file)
                },
                /**
                 * Reset error
                 */
                resetError() {
                    this.error = null
                }
            },
            mounted(){
                this.isMounted = true;
            }
        });

        app.config.compilerOptions.delimiters = ['v{', '}'];

        app.component('file-upload', VueUploadComponent);

        // mount the app
        app.mount(this.$element);
    }
}