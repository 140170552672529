export default class header {
    constructor($header) {
        this.$header = $header;
        this.$logo = this.$header.querySelector("[data-logo]");
        this.$menu = this.$header.querySelector('[data-menu]');
        this.$menuItems = this.$header.querySelectorAll("[data-menu-item]");
        this.$menuTrigger = this.$header.querySelector('[data-menu-trigger]');
        this.$menuTriggerIcon = this.$header.querySelector('[data-menu-trigger-icon]');

        this.checkSticky = this.checkSticky.bind(this);
        this.toggleActiveMenuItem = this.toggleActiveMenuItem.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);

        document.addEventListener('scroll', this.checkSticky);
        this.$menuItems.forEach($menuItem => $menuItem.addEventListener('click', this.toggleActiveMenuItem));

        if (this.$menuTrigger) {
            this.$menuTrigger.addEventListener('click', this.toggleMenu);
        }

        document.addEventListener('click', (event) => {
            var isClickInsideElement = this.$menu.contains(event.target) || event.target === this.$menuTrigger || this.$menuTrigger.contains(event.target);

            if ((!isClickInsideElement && this.$menu.classList.contains('header__menus--open'))) {
                this.closeMenu();
            }
        });

        this.checkSticky();
    }

    /**
     * Toggle Active menu item
     * @param e
     */
    toggleActiveMenuItem(e) {
        const $activeItem = this.$header.querySelector('.header-menu__link--active');
        const itemPosition = e.target.getBoundingClientRect();

        if (! $activeItem) {
            return;
        }

        const prevItemPosition = $activeItem.getBoundingClientRect();

        const move = (prevItemPosition.left + (prevItemPosition.width / 2)) - (itemPosition.left + (itemPosition.width / 2));

        e.target.style.setProperty('--star-start-translate-x', move + 'px')
        e.target.classList.add('header-menu__link--active-move');
        $activeItem.classList.remove('header-menu__link--active');
    }

    /**
     * Check sticky
     */
    checkSticky() {
        let scrollPosition = window.pageYOffset;

        if (scrollPosition > 50) {
            this.$header.classList.add('header--sticky');
            this.$logo.classList.add('header__logo--compact');
        } else {
            this.$header.classList.remove('header--sticky');
            this.$logo.classList.remove('header__logo--compact');
        }
    }

    /**
     * Open menu
     * @param e
     */
    openMenu(e) {
        e.preventDefault();
        this.$menu.classList.add('header__menus--open');
        this.$menuTrigger.classList.add('header__menu-trigger--open');
        this.$menuTriggerIcon.classList.add('icon-hamburger__lines--open');
        document.querySelector('html').classList.add('no-scroll');
    }

    /**
     * Close menu
     */
    closeMenu() {
        this.$menu.classList.remove('header__menus--open');
        this.$menuTrigger.classList.remove('header__menu-trigger--open');
        this.$menuTriggerIcon.classList.remove('icon-hamburger__lines--open');
        document.querySelector('html').classList.remove('no-scroll');
    }

    /**
     * Toggle menu
     * @param e
     */
    toggleMenu(e) {
        e.preventDefault();
        this.$menu.classList.toggle('header__menus--open');
        this.$menuTrigger.classList.toggle('header__menu-trigger--open');
        this.$menuTriggerIcon.classList.toggle('icon-hamburger__lines--open');
        document.querySelector('html').classList.toggle('no-scroll');
    }
}