import Modal from "./modal";

export default class Messenger extends Modal {
    constructor() {
        const $modal = document.querySelector('[data-messenger-modal]');
        super($modal);

        this.$messengerContent = document.querySelector('[data-messenger-content]');
    }

    /**
     * Show
     * @param message
     * @param type
     */
    show(message, type) {
        if (type === 'info') {
            this.addIcon('icon-info-circle', type);
        } else if (type === 'success') {
            this.addIcon('icon-success-circle', type);
        } else if (type === 'error' || type === 'warning') {
            this.addIcon('icon-warning-circle', type);
        }

        this.$messengerContent.innerHTML = message;
        this.showModal();
    }

    /**
     * Add icon
     * @param iconClass
     * @param type
     */
    addIcon(iconClass, type) {
        const $icon = document.createElement('i');
        $icon.classList.add('modal__icon');
        $icon.classList.add(iconClass);
        $icon.classList.add('text-color--' + type);
        this.$messengerContent.before($icon);
    }

    /**
     * Close message
     * @param e
     */
    closeModal(e) {
        super.closeModal(e);

        if (this.$modal.querySelector('.modal__icon')) {
            setTimeout(() => this.$modal.querySelector('.modal__icon').remove(), 300);
        }
    }
}
