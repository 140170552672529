import Swiper from "../../../../../assets/vendor/swiper/swiper-bundle.esm.browser";

export default class CharacterList {
    constructor($el) {
        this.$el = $el;
        this.$navigationTrigger = this.$el.querySelectorAll('[data-navigation-trigger]');
        this.$slider = this.$el.querySelector('[data-slider]');

        this.goToSlide = this.goToSlide.bind(this);
        this.checkActiveTrigger = this.checkActiveTrigger.bind(this);
        this.setActiveTrigger = this.setActiveTrigger.bind(this);

        this.slider = new Swiper(this.$slider, {
            //loop: true,
            slidesPerView: 1,
            spaceBetween: 24,
            centeredSlides: true,
            centeredSlidesBounds: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                init: this.checkActiveTrigger,
                slideChange: this.checkActiveTrigger,
            }
        });


        // this.slider.on('init', this.checkActiveTrigger);
        // this.slider.on('slideChange', this.checkActiveTrigger);
        this.$navigationTrigger.forEach($item => $item.addEventListener('click', this.goToSlide));
    }

    /**
     * Go to slide
     * @param e
     */
    goToSlide(e) {
        e.preventDefault();
        const $trigger = e.target.dataset.navigationTrigger ?
                e.target:
                e.target.closest('[data-navigation-trigger]');
        const slideId =  $trigger.dataset.navigationTrigger;
        console.log(slideId);
        this.$navigationTrigger.forEach($trigger => $trigger.classList.remove('character-list__coin--active'));
        $trigger.classList.add('character-list__coin--active');

        this.slider.slideToLoop(slideId);
        console.log(this.slider.activeIndex);
    }

    checkActiveTrigger(swiper) {
        const $trigger = this.$el.querySelector('[data-navigation-trigger="' + swiper.realIndex + '"]');
        this.setActiveTrigger($trigger);
    }

    setActiveTrigger($trigger) {
        this.$navigationTrigger.forEach($trigger => $trigger.classList.remove('character-list__coin--active'));
        $trigger.classList.add('character-list__coin--active');
    }
}