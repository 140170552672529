export default class Dropdown {
    /**
     * Constructor
     * @param $el
     */
    constructor($el) {
        this.$el = $el;
        this.$trigger = this.$el.querySelector('[data-dropdown-trigger]');
        this.$menu = this.$el.querySelector('[data-dropdown-menu]');

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);

        this.$trigger.addEventListener('click', this.toggleDropdown);

        // click outside
        document.addEventListener('click', (e) => {
            const isTrigger = e.target.closest('[data-dropdown-trigger]');

            if (!this.$menu.contains(e.target) && !isTrigger) {
                this.closeDropdown();
            }
        });
    }

    /**
     * Toggle dropdown
     * @param e
     */
    toggleDropdown(e) {
        e.preventDefault();

        this.$trigger.classList.toggle('dropdown__trigger--active');
        this.$menu.classList.toggle('dropdown__menu--open');
    }

    /**
     * Close dropdown
     */
    closeDropdown() {
        this.$trigger.classList.remove('dropdown__trigger--active');
        this.$menu.classList.remove('dropdown__menu--open');
    }
}