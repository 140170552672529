import Choices from "./../../../../../assets/vendor/choices.js/public/assets/scripts/choices";

export default class Autocomplete {
    constructor($el) {
        this.$el = $el;
        this.isMultiple = $el.getAttribute('multiple') !== null;

        this.checkShowOptions = this.checkShowOptions.bind(this);
        this.checkReplaceValue = this.checkReplaceValue.bind(this);
        this.hideChoices = this.hideChoices.bind(this);

        this.$el.multiple = true;
        this.choices = (new Choices(this.$el, {
            placeholder: this.$el.dataset.selectPlaceholder !== undefined,
            placeholderValue: this.$el.dataset.selectPlaceholder,
            searchEnabled: true,
            addChoices: false,
            noResultsText: translations.noResultsText,
            noChoicesText: translations.noChoicesText,
            itemSelectText: '',
            uniqueItemText: translations.uniqueItemText,
            customAddItemText: translations.customAddItemText,
            renderChoiceLimit: 5,
            searchResultLimit: 5,
            maxItemCount: 0,
            loadingText: this.$el.dataset.selectPlaceholder,
            fuseOptions: {
                minMatchCharLength: 3,
                threshold: 0.4
            },
            classNames: {
                listItems: this.isMultiple ? ['choices__list--multiple'] :['choices__list--single choices__list--autocomplete-single'],
            }
        }));

        this.choices.removeActiveItemsByValue('');

        this.choices.input.element.addEventListener('input', this.checkShowOptions);
        this.choices.passedElement.element.addEventListener('showDropdown',this.checkShowOptions);
        this.choices.passedElement.element.addEventListener('choice',this.checkReplaceValue);
        this.choices.passedElement.element.addEventListener('addItem', this.hideChoices);
    }

    /**
     * Check Show options
     */
    checkShowOptions(e) {
        console.log(e,this.choices.input.value);
        if(this.choices.input.value.length < 3) {
            this.choices.choiceList.element.classList.remove('is-active');
            this.choices.dropdown.element.classList.remove('is-active');
            this.choices.containerOuter.element.classList.remove('is-open');
        } else {
            this.choices.choiceList.element.classList.add('is-active');
            this.choices.dropdown.element.classList.add('is-active');
            this.choices.containerOuter.element.classList.add('is-open');
        }
    }

    /**
     * Hide choices dropdown
     */
    hideChoices() {
        this.choices.hideDropdown();
    }

    /**
     * Check if we need to replace the value instead of append it
     * Because we made the select a multiple select this functions is responsible for the selects that
     * only allow 1 value when we already have a value we will remove it and replace it with the newly selected value
     * @param choice
     */
    checkReplaceValue(choice) {
        if (this.choices.getValue().length && !this.isMultiple) {
            this.choices.removeActiveItems();
        }
    }
}