import FormControl from "./form-control";
import FormSteps from "./form-steps";
import Loader from "./loader";

export default class Form {
    constructor($form) {
        this.$form = $form;
        this.$captchaInput = this.$form.querySelector('[data-recaptcha]');
        this.$error = this.$form.querySelector('.form__group--error');
        this.$loader = this.$form.querySelector('[data-form-loader]');
        this.useCaptcha = this.$captchaInput !== null;
        this.submitted = false;
        this.steps = [];
        this.activeStepKey = null;
        this.loader = this.$form.querySelector('[data-form-loader]')
                ? (new Loader(this.$form.querySelector('[data-form-loader]')))
                : null;
        this.formSteps = this.$form.querySelectorAll('[data-form-step]').length > 1
                ? (new FormSteps($form, this))
                : null;
        this.inputs = [];

        this.$form.querySelectorAll('input:not([type="hidden"]), select, textarea').forEach($input => this.inputs.push(new FormControl($input)));

        this.onSubmit = this.onSubmit.bind(this);
        this.initSteps = this.initSteps.bind(this);
        this.getCaptchaToken = this.getCaptchaToken.bind(this);
        this.setCaptchaToken = this.setCaptchaToken.bind(this);
;
        this.$form.addEventListener('submit', this.onSubmit);

        if (this.steps.length > 1) {
            this.initSteps();
        }

        // if we have an error
        if (this.$error) {
             window.scrollTo(0, (this.$error.getBoundingClientRect().top + window.scrollY) - document.querySelector('[data-header]').getBoundingClientRect().height);
        }
    }

    /**
     * On submit
     * we will show a loader and check if we need to add a captcha token
     * @param e
     */
    onSubmit(e) {
        e.preventDefault();
        this.loader.loading(true);

        if (this.submitted) {
            return;
        }

        if ( ! this.useCaptcha) {
            this.submitted = true;
            this.$form.submit();
            return;
        }

        grecaptcha.ready(this.getCaptchaToken);
    }

    /**
     * Get the captcha token
     * @returns {Promise<void>}
     */
    async getCaptchaToken() {
        const token = await grecaptcha.execute(this.$captchaInput.dataset.key, {action: 'homepage'});
        this.setCaptchaToken(token);
    }

    /**
     * Set the captcha token
     * @param token
     */
    setCaptchaToken(token) {
        this.$captchaInput.value = token;
        this.submitted = true;

        this.$form.submit();
    }


    initSteps() {
        this.activeStepKey = 0;
        this.steps[this.activeStepKey].setActive();
    }
}
